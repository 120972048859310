<template>
  <b-card>
    <b-card v-if="is_staff" class="bg-light-secondary mb-1" title="Filters">
      <b-form inline style="display: inline-block; width: 100%">
        <div class="d-flex flex-row align-items-center justify-content-around">
          <div class="w-100 d-flex">
            <v-select
              v-model="org_id"
              label="name"
              placeholder="--Select Organization--"
              :options="organizations"
              @input="load"
              autocomplete
              style="width: 50%"
              :reduce="(org_id) => org_id.tenant_uid"
            />
            <b-button class="ml-1" variant="primary" @click="load">
              <span v-if="isloading"
                ><b-spinner type="border" small></b-spinner> Please wait</span
              >
              <span v-else>
                <feather-icon icon="SearchIcon" class="mr-50" />Search</span
              >
            </b-button>
          </div>

          <!-- size="sm"  -->
        </div>
      </b-form>
    </b-card>
    <div class="d-flex justify-content-center mb-1" v-if="loading">
      <b-spinner class="float-right" label="Floated Right" />
    </div>
    <div v-if="!loading">
      <b-table
        :fields="fields"
        :items="items"
        responsive
        :per-page="perPage"
        v-if="items.length"
      >
        <template #cell(index)="data">
          {{ data.index + 1 + perPage * (currentPage - 1) }}
        </template>
        <template #cell(deleted_at)="data">
          <div class="d-flex">
            {{ data.item.deleted_at | formatdate }}
          </div>
        </template>
        <template #cell(action)="data">
          <div class="d-flex">
            <b-button variant="primary" size="sm" @click="revert(data.item.id)">
              <span v-if="isloading && data.item.id == userId"
                ><b-spinner type="border" small></b-spinner> Please wait</span
              >
              <span v-else>Revert</span>
            </b-button>
            <b-button
              variant="danger"
              class="ml-1"
              size="sm"
              @click="gotopermanentDelete(data.item.id, data.item.full_name)"
            >
              Delete
            </b-button>
          </div>
        </template>
      </b-table>
      <div v-else>
        <div
          class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
        >
          <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
          <h3 class="font-weight-bolder">No Data Found</h3>
        </div>
      </div>
    </div>
    <b-pagination-nav
      v-model="currentPage"
      :numberOfPages="total"
      :link-gen="linkGen"
      :total-rows="rows"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="dataTable"
      use-router
    />
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text style="max-width: 100%; word-wrap: break-word">
        Do you really want to permanent delete
        <span
          class="text-primary"
          style="max-width: 100%; display: inline-block"
          >"{{ username }}"</span
        >
        ?
      </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="permanentDelete()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
  </b-card>
</template>
<script>
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard,
  BTable,
  BBadge,
  BButton,
  BModal,
  BCardText,
  BSpinner,
  BPaginationNav,
} from "bootstrap-vue";
import moment from "moment";
export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BButton,
    BModal,
    BCardText,
    BSpinner,
    BPaginationNav,
    vSelect,
  },
  data() {
    return {
      // org_id: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      is_staff: this.$store.state.app.user.is_staff,
      org_id: this.$store.state.app.org_id,
      organizations: [],
      userId: "",
      username: "",
      items: [],
      fields: [
        { key: "index", label: "#" },
        { key: "full_name", label: "Name" },
        // { key: "username", label: "email" },
        { key: "profile.o_profile[0].org_name", label: "Organization Name" },
        { key: "deleted_by", label: "Deleted By" },
        { key: "deleted_at", label: "Deleted At" },
        { key: "action", label: "Action" },
      ],
      openDeleteModal: false,
      isloading: false,
      pagination_pos: "center",
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      loading: false,
    };
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.load();
    },
  },
  created: function () {
    this.loadOrg();
    this.load();
  },
  methods: {
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    loadOrg: function () {
      if (this.is_staff) {
        this.organizations = this.$store.state.app.workspaces.filter(
          (org) => org.tenant_uid === this.org_id
        );
        this.organizations.push({
          name: "Secusy System",
          tenant_uid: "0",
          enabled_modules: [],
        });
      } else {
        this.org_id = this.$store.state.app.org_id;
      }
    },
    load: function (search = false) {
      this.loading = true;
      let url =
        process.env.VUE_APP_BASEURL +
        "user/users/deleted-users/?page=" +
        this.currentPage;
      if (this.org_id == "0") {
        url = url + "&role_type=0";
      }
      // if (this.org_id != null) {
      //   url = url + "&q_org_id=" + this.org_id;
      // }
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        // this.toastMessage(res)  // method call to method2
        this.items = res.data.results;
        this.rows = res.data.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.loading = false;
      });
    },

    revert: function (id) {
      this.isloading = true;
      this.userId = id;
      let formData = new FormData();
      formData.append("user_id", this.userId);
      //   let data = {
      //     asset_id: this.assetId,
      //   };

      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data: formData,
        url: process.env.VUE_APP_BASEURL + "user/users/revert-user/",
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.isloading = false;
        // this.openDeleteModal = false;
        this.load();
        // if (res.data.id) {
        //   this.$router.push({ name: "Users" });
        // }
      });
    },
    gotopermanentDelete: function (id, full_name) {
      this.openDeleteModal = true;
      this.userId = id;
      this.username = full_name;
    },
    permanentDelete: function () {
      let formData = new FormData();
      formData.append("user_id", this.userId);

      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data: formData,
        url: process.env.VUE_APP_BASEURL + "user/users/hard-delete-user/",
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.openDeleteModal = false;
        this.load();
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>